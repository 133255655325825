import React, { useEffect, useState, Suspense, lazy } from 'react';
import { useNavigate } from 'react-router-dom';
import ChampionXLogoDT from '../../../images/championx-logo-png.png';
import '../../../onboarding/OnboardingShell.scss';
import Splash from './Splash';

const UsernameInput = lazy(() => import('./UsernameInput'));
const PasswordInput = lazy(() => import('./PasswordInput'));
import AuthService from '../../../services/authentication/auth.service';
import { LoginModel } from '../../../models/Login';
import './Login.scss';

const Loader = lazy(() => import('../Loader/Loader'));

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSplash, setShowSplash] = useState(false); // Track splash screen visibility

  const navigate = useNavigate();

  useEffect(() => {
    if (AuthService.isUserLoggedInLocal() === true) {
      navigate('/');
    }
  }, []);

  const handleUsernameChange = (value: string) => {
    setUsername(value);
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoginError('');
    const userCredentials: LoginModel = {
      grantType: 'form',
      userName: username,
      passwordHash: password,
    };

    if (username !== '' && password !== '') {
      setIsLoading(true);
      await AuthService.login(userCredentials)
        .then((response) => {
          if (response === 'firstTimeLogin' || response === 'success') {
            // Show the splash screen
            setIsLoading(false);
            setShowSplash(true);

            // Hide splash screen after 2 seconds and navigate to the appropriate screen
            setTimeout(() => {
              setShowSplash(false);

              if (response === 'firstTimeLogin') {
                navigate('/onboarding');
              } else if (response === 'success') {
                navigate('/');
              }
            }, 2000);
          } else {
            setLoginError('Invalid login. If needed, please contact your administrator.');
            setIsLoading(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  return (
    <Suspense fallback={<Loader />}>
      {showSplash ? (
        <Splash />
      ) : (
        <div data-testid='onboarding-shell' className='onboarding-shell'>
          <div className='onboarding-shell-left'>
            <div className='onboarding-shell-left-container'>
              <div className='onboarding-shell-content'>
                <div className='onboarding-shell-header'>
                  <div className='onboarding-shell-header-logo-container'>
                    <img src={ChampionXLogoDT} alt='ChampionX Logo' className='onboarding-shell-header-logo' />
                  </div>
                </div>
                <div className='login-container'>
                  <div className='login-title'>Log in</div>
                  <div className='login-sub-title'>Welcome to ALLY. Please enter your existing XSPOC credentials.</div>
                  <form data-testid='submit' onSubmit={handleSubmit}>
                    <UsernameInput onUsernameChange={handleUsernameChange} />
                    <PasswordInput onPasswordChange={handlePasswordChange} />
                    {loginError && <div style={{ color: 'red' }}>{loginError}</div>}
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <button className='login-btn' type='submit'>
                        Sign in
                      </button>
                    )}
                  </form>
                </div>
              </div>
              <div className='onboarding-shell-footer'>© ChampionX {new Date().getFullYear()}</div>
            </div>
          </div>
          <div className='onboarding-shell-right'>
            <div className='onboarding-shell-right'>
              <div className='onboarding-shell-image login' data-testid='whats-new'>
                <div className='login-image'>Login</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Suspense>
  );
};

export default Login;
