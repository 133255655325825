import api from '../api';

export interface ApplicationSettingsResponse {
  values: {
    ApplicationDeploymentMode: string;
    EnablePumpChecker: string;
    HostingEnvironment: string;
  };
  id: string;
  dateCreated: string;
}

const fetchApplicationSettingsAsync = async (): Promise<ApplicationSettingsResponse | null> => {
  try {
    const response = await api.get<ApplicationSettingsResponse>('/Settings/GetApplicationSettings', {
      headers: {
        'content-type': 'application/json',
      },
    });

    if (response && response.data) {
      return response.data;
    } else {
      console.log('No data found in response', response);
      return null;
    }
  } catch (error) {
    console.error('Error fetching application settings', error);
    return null;
  }
};

export { fetchApplicationSettingsAsync };
