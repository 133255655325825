import { useEffect, useState } from 'react';
import { fetchSettingUrlsAsync, fetchLoginSettingsAsync } from '../services/settingsURL/settingUrls.service';

let cachedUrls: {
  allyOnboardingURL: string | null;
  pumpCheckerURL: string | null;
} | null = null;

let cachedLoginSettingsSettings: {
  loginURL: string | null;
  isCloudDeployment: boolean | null;
  isConnexiaLogin: boolean | null;
} | null = null;

const useSettingUrls = () => {
  const [allyOnboardingURL, setAllyOnboardingURL] = useState<string | null>(null);
  const [pumpCheckerURL, setPumpCheckerURL] = useState<string | null>(null);

  useEffect(() => {
    const getUrls = async () => {
      if (cachedUrls) {
        setAllyOnboardingURL(cachedUrls.allyOnboardingURL);
        setPumpCheckerURL(cachedUrls.pumpCheckerURL);
      } else {
        const urls = await fetchSettingUrlsAsync();
        if (urls) {
          cachedUrls = {
            allyOnboardingURL: urls.values.AllyOnboardingUIURL
              ? `${urls.values.AllyOnboardingUIURL}/asset-details`
              : null,
            pumpCheckerURL: urls.values.PumpCheckerURL ? `${urls.values.PumpCheckerURL}/ESPDiagnosis` : null,
          };

          setAllyOnboardingURL(cachedUrls.allyOnboardingURL);
          setPumpCheckerURL(cachedUrls.pumpCheckerURL);
        }
      }
    };

    getUrls();
  }, []);

  return { allyOnboardingURL, pumpCheckerURL };
};

let settingsPromise: Promise<void> | null = null;

const useLoginSettings = () => {
  const [loginURL, setLoginURL] = useState<string | null>(null);
  const [isCloudDeployment, setIsCloudDeployment] = useState<boolean | null>(null);
  const [isConnexiaLogin, setConnexiaLogin] = useState<boolean | null>(null);

  useEffect(() => {
    if (cachedLoginSettingsSettings) {
      setLoginURL(cachedLoginSettingsSettings.loginURL);
      setIsCloudDeployment(cachedLoginSettingsSettings.isCloudDeployment);
      setConnexiaLogin(cachedLoginSettingsSettings.isConnexiaLogin);
    } else {
      if (!settingsPromise) {
        settingsPromise = fetchLoginSettingsAsync().then((settings) => {
          if (settings) {
            cachedLoginSettingsSettings = {
              loginURL: settings.values.LoginURL ?? null,
              isCloudDeployment: settings.values.ApplicationDeploymentMode?.toLowerCase() !== 'onprem',
              isConnexiaLogin: settings.values.LoginMode?.toLowerCase() === 'connexialogin',
            };
          }
        });
      }

      settingsPromise.then(() => {
        if (cachedLoginSettingsSettings) {
          setLoginURL(cachedLoginSettingsSettings.loginURL);
          setIsCloudDeployment(cachedLoginSettingsSettings.isCloudDeployment);
          setConnexiaLogin(cachedLoginSettingsSettings.isConnexiaLogin);
        }
      });
    }
  }, []);

  return { loginURL, isCloudDeployment, isConnexiaLogin };
};

export { useSettingUrls, useLoginSettings };
