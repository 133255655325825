import React from 'react';
import AuthService from '../../services/authentication/auth.service';

import { Navigate, useLocation } from 'react-router-dom';
import { useLoginSettings } from '../../hooks/useSettingUrls';

interface AuthComponentProps {
  component: React.FC;
}

const AuthComponent: React.FC<AuthComponentProps> = ({ component: Component }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ref = queryParams.get('ref');
  const redirect = ref ? '/login?ref=' + ref : '/login';
  const { isCloudDeployment, isConnexiaLogin } = useLoginSettings();

  return AuthService.isUserLoggedInLocal() ? (
    <Component />
  ) : isConnexiaLogin || !isCloudDeployment ? (
    <Navigate to={redirect} replace />
  ) : (
    <Navigate to={'/login'} />
  );
};

export default AuthComponent;
