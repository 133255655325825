import { useEffect, useState } from 'react';
import { fetchApplicationSettingsAsync } from '../services/configuration/config';

let cachedSettings: {
  isCloud: boolean | null;
  enablePumpChecker: boolean | null;
  hostingEnvironment: boolean | null;
} | null = null;

const useConfig = () => {
  const [isCloud, setIsCloud] = useState<boolean | null>(null);
  const [enablePumpChecker, setEnablePumpChecker] = useState<boolean | null>(null);
  const [hostingEnvironment, setHostingEnvironment] = useState<boolean | null>(null);

  useEffect(() => {
    const getSettings = async () => {
      if (cachedSettings) {
        setIsCloud(cachedSettings.isCloud);
        setEnablePumpChecker(cachedSettings.enablePumpChecker);
        setHostingEnvironment(cachedSettings.hostingEnvironment);
      } else {
        const settings = await fetchApplicationSettingsAsync();
        if (settings) {
          const newSettings = {
            isCloud: settings.values.ApplicationDeploymentMode?.toLowerCase() !== 'onprem',
            enablePumpChecker: settings.values.EnablePumpChecker?.toLowerCase() === 'true',
            hostingEnvironment: settings.values.HostingEnvironment?.toLowerCase() !== 'localad',
          };

          cachedSettings = newSettings;

          setIsCloud(newSettings.isCloud);
          setEnablePumpChecker(newSettings.enablePumpChecker);
          setHostingEnvironment(newSettings.hostingEnvironment);
        }
      }
    };

    getSettings();
  }, []);

  return { isCloud, enablePumpChecker, hostingEnvironment };
};

export default useConfig;
