import { LoginURLModel } from '../../models/LoginURL';
import { SettingUrlsModel } from '../../models/settingUrls';
import api from '../api';

const fetchSettingUrlsAsync = async (): Promise<SettingUrlsModel | null> => {
  try {
    const response = await api.get<SettingUrlsModel>('/Settings/GetAppURLs', {
      headers: {
        'content-type': 'application/json',
      },
    });

    if (response && response.data) {
      return response.data;
    } else {
      console.log('No data found in response', response);
      return null;
    }
  } catch (error) {
    console.error('Error fetching onboarding URLs', error);
    return null;
  }
};

const fetchLoginSettingsAsync = async (): Promise<LoginURLModel | null> => {
  try {
    const response = await api.get<LoginURLModel>('/Settings/GetLoginURL', {
      headers: {
        'content-type': 'application/json',
      },
    });

    if (response && response.data) {
      return response.data;
    } else {
      console.log('No data found in response', response);
      return null;
    }
  } catch (error) {
    console.error('Error fetching login URL', error);
    return null;
  }
};

export { fetchSettingUrlsAsync, fetchLoginSettingsAsync };
