import Token from '../../models/Token';
import { LoginModel, Reference } from '../../models/Login';
import Helpers from '../../utilities/Helpers';
import api from '../api';
import TokenService from './token.service';
import GrantTypeConstant from '../../constants/grant-type.constant';
import { v4 as uuidv4 } from 'uuid';
import { getAllyConnectURL } from '../../utilities/BaseURLUtility';

const logout = async (loginURL: string | null) => {
  const host = window.location.host;

  return await api
    .get('/Logout')
    .then(function (response: any) {
      if (response) {
        if (response.status === 200) {
          TokenService.removeUser();
          window.location.replace(getAllyConnectURL(loginURL) + `/Account/Logout?app=${host}`);
        } else {
          Helpers.ConsoleLog(response?.status, 'error');
        }
      } else {
        Helpers.ConsoleLog(response?.status, 'error');
      }
    })
    .catch(function (error) {
      Helpers.ConsoleLog(error, 'error');
      return 'failed';
    });
};

const formLogout = () => {
  TokenService.removeUser();
  window.location.replace('/login');
};

const isUserLoggedInLocal = (): boolean => {
  return TokenService.getUser() != null;
};

const isUserLoggedIn = async (refParam: string | null): Promise<boolean> => {
  if (isUserLoggedInLocal() === true) {
    return true;
  }

  if (refParam === null && refParam !== '') {
    return false;
  }

  const loginModel: Reference = {
    grantType: GrantTypeConstant.reference,
    refreshToken: '',
    reference: refParam,
  };

  try {
    const response = await LoginByRefToken(loginModel);
    if (response === 'success') {
      return true;
    }
    return false;
  } catch (error) {
    Helpers.ConsoleLog(error, 'error');
    return false;
  }
};

const LoginByRefToken = async (login: Reference) => {
  const uuid = uuidv4();

  return await api
    .post('/LoginByRefToken', login.reference, {
      headers: {
        'content-type': 'application/json',
        'xspoc-ui-tracking-id': uuid,
      },
    })
    .then(function (response: any) {
      if (response?.data && response?.data?.access_token) {
        const data: Token = response.data;
        TokenService.addOrUpdateLocalCorrelationId(uuid);
        TokenService.addOrUpdateIsAdmin(data?.isAdmin);
        TokenService.setUser('success');

        if (data?.isFirstTimeLogin === true) {
          return 'firstTimeLogin';
        }

        return 'success';
      }

      return 'failed';
    })
    .catch(function (error) {
      Helpers.ConsoleLog(error, 'error');
      return 'failed';
    });
};

const login = async (login: LoginModel) => {
  const uuid = uuidv4();

  return await api
    .post(
      '/FormLogin',
      {
        grantType: GrantTypeConstant.form,
        userName: login.userName,
        passwordHash: login.passwordHash,
      },
      {
        headers: {
          'content-type': 'application/json',
          'xspoc-ui-tracking-id': uuid,
        },
      },
    )
    .then(function (response: any) {
      if (response) {
        const data: Token = response.data;
        TokenService.addOrUpdateLocalCorrelationId(uuid);
        TokenService.addOrUpdateIsAdmin(data?.isAdmin);
        TokenService.setUser('success');

        if (data?.isFirstTimeLogin === true) {
          return 'firstTimeLogin';
        }

        return 'success';
      }

      return 'failed';
    })
    .catch(function (error) {
      Helpers.ConsoleLog(error, 'error');
      return 'failed';
    });
};

const refreshTokenFromServer = async () => {
  try {
    await api
      .post('RefreshToken')
      .then((response) => {
        if (response.status === 200) {
          TokenService.setUser('success');
          return true;
        } else {
          return false;
        }
      })
      .catch(function (error) {
        Helpers.ConsoleLog(error, 'error');
        return false;
      });
    return false;
  } catch (error) {
    Helpers.ConsoleLog(error, 'error');
    return false;
  }
};

const getWellControlAction = async () => {
  const param: any = {
    assetId: '0ec78817-a654-47dc-8fc4-a8a139e573b0',
    testDate: '12/14/2022',
    analysisTypeId: 1,
    analysisResultId: 1358,
  };

  await api.get('Analytics/GLAnalysis' + (param as string)).then((response: any) => {
    if (response) {
      return response;
    } else {
      return null;
    }
  });
};

const AuthService = {
  logout,
  isUserLoggedInLocal,
  isUserLoggedIn,
  refreshTokenFromServer,
  login,
  getWellControlAction,
  formLogout,
};

export default AuthService;
